import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ISuggestion {
id:number,
name:string,
mobilenumber:number,
email:string,
message:string
}

interface ISuggestionData {
    list?: Array<ISuggestion>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: ISuggestionData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const suggestionSlice = createSlice({
    name: "suggestion",
    initialState,
    reducers: {
        setSuggestionList: (state, _action: PayloadAction<ISuggestionData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetSuggestionToInit: (state) => {
            state = initialState;
        },
        setSuggestionMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setSuggestionList, resetSuggestionToInit, setSuggestionMessage } = suggestionSlice.actions;

export default suggestionSlice.reducer;

