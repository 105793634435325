import { APIService } from "services";

export const getPhotos = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllPhotos(pageNo,pageSize);
    }
    else{
        try {
            res = await searchPhotos(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addPhotos = (data) => {
return APIService.api().post(`/photos`,data)
}
export const updatePhotos = (data) => {
return APIService.api().put(`/photos/${data.id}`,data)
}
export const getAllPhotos = (page,paginator) => {
return APIService.api().get(`/photos/?page=${page}&paginator=${paginator}`)
}
export const getOnePhotos = (id) => {
return APIService.api().get(`/photos/${id}`)
}
export const searchPhotos = (searchKey,page,paginator) => {
return APIService.api().get(`/photos/search/${searchKey}/?page=${page}&paginator=${paginator}`)
}
export const deletePhotos = (id) => {
return APIService.api().delete(`/photos/${id}`)
}
