import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from "./login";
import Register from "./register";
import AuthenticatedRoute from "components/auth";
import { Dashboard } from "components/dashboard";
import { FileUpload } from "components/upload";
import { NotFound } from "./404";
import { Videos, Users, Suggestion, Photos, News, Events, Enquiry, Careers, Achivements} from "components";
const Pages: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}>
        </Route>
        <Route path="/register" element={<Register />}>
        </Route>
        <Route path="/dashboard" element={<AuthenticatedRoute element={<Dashboard />} />}></Route>
        <Route path="/upload" element={<AuthenticatedRoute element={<FileUpload />} />}></Route>
        <Route path="/videos" element={<AuthenticatedRoute element={<Videos />} />}></Route>
<Route path="/users" element={<AuthenticatedRoute element={<Users />} />}></Route>
<Route path="/suggestion" element={<AuthenticatedRoute element={<Suggestion />} />}></Route>
<Route path="/photos" element={<AuthenticatedRoute element={<Photos />} />}></Route>
<Route path="/news" element={<AuthenticatedRoute element={<News />} />}></Route>
<Route path="/events" element={<AuthenticatedRoute element={<Events />} />}></Route>
<Route path="/enquiry" element={<AuthenticatedRoute element={<Enquiry />} />}></Route>
<Route path="/careers" element={<AuthenticatedRoute element={<Careers />} />}></Route>
<Route path="/achivements" element={<AuthenticatedRoute element={<Achivements />} />}></Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;

