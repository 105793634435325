import { APIService } from "services";

export const getEvents = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllEvents(pageNo,pageSize);
    }
    else{
        try {
            res = await searchEvents(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addEvents = (data) => {
return APIService.api().post(`/events`,data)
}
export const updateEvents = (data) => {
return APIService.api().put(`/events/${data.id}`,data)
}
export const getAllEvents = (page,paginator) => {
return APIService.api().get(`/events/?page=${page}&paginator=${paginator}`)
}
export const getOneEvents = (id) => {
return APIService.api().get(`/events/${id}`)
}
export const searchEvents = (searchKey,page,paginator) => {
return APIService.api().get(`/events/search/${searchKey}/?page=${page}&paginator=${paginator}`)
}
export const deleteEvents = (id) => {
return APIService.api().delete(`/events/${id}`)
}
