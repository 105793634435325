import { APIService } from "services";

export const getEnquiry = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllEnquiry(pageNo,pageSize);
    }
    else{
        try {
            res = await searchEnquiry(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addEnquiry = (data) => {
return APIService.api().post(`/enquiry`,data)
}
export const updateEnquiry = (data) => {
return APIService.api().put(`/enquiry/${data.id}`,data)
}
export const getAllEnquiry = (page,paginator) => {
return APIService.api().get(`/enquiry/?page=${page}&paginator=${paginator}`)
}
export const getOneEnquiry = (id) => {
return APIService.api().get(`/enquiry/${id}`)
}
export const searchEnquiry = (searchKey,page,paginator) => {
return APIService.api().get(`/enquiry/search/${searchKey}/?page=${page}&paginator=${paginator}`)
}
export const deleteEnquiry = (id) => {
return APIService.api().delete(`/enquiry/${id}`)
}
