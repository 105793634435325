import { APIService } from "services";

export const getSuggestion = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllSuggestion(pageNo,pageSize);
    }
    else{
        try {
            res = await searchSuggestion(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addSuggestion = (data) => {
return APIService.api().post(`/suggestion`,data)
}
export const updateSuggestion = (data) => {
return APIService.api().put(`/suggestion/${data.id}`,data)
}
export const getAllSuggestion = (page,paginator) => {
return APIService.api().get(`/suggestion/?page=${page}&paginator=${paginator}`)
}
export const getOneSuggestion = (id) => {
return APIService.api().get(`/suggestion/${id}`)
}
export const searchSuggestion = (searchKey,page,paginator) => {
return APIService.api().get(`/suggestion/search/${searchKey}/?page=${page}&paginator=${paginator}`)
}
export const deleteSuggestion = (id) => {
return APIService.api().delete(`/suggestion/${id}`)
}
