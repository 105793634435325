import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPhotos {
id:number,
image:string,
created_at?:Date,
updated_at?:Date
}

interface IPhotosData {
    list?: Array<IPhotos>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IPhotosData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const photosSlice = createSlice({
    name: "photos",
    initialState,
    reducers: {
        setPhotosList: (state, _action: PayloadAction<IPhotosData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetPhotosToInit: (state) => {
            state = initialState;
        },
        setPhotosMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setPhotosList, resetPhotosToInit, setPhotosMessage } = photosSlice.actions;

export default photosSlice.reducer;

