import { combineReducers } from "redux";

import template from "redux/slices/template";
import authToken from "redux/slices/auth";

import videos from "redux/slices/videos";
import users from "redux/slices/users";
import suggestion from "redux/slices/suggestion";
import photos from "redux/slices/photos";
import news from "redux/slices/news";
import events from "redux/slices/events";
import enquiry from "redux/slices/enquiry";
import careers from "redux/slices/careers";
import achivements from "redux/slices/achivements";


const rootReducer = combineReducers({ template,authToken,videos,users,suggestion,photos,news,events,enquiry,careers,achivements });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

