import { APIService } from "services";

export const getAchivements = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllAchivements(pageNo,pageSize);
    }
    else{
        try {
            res = await searchAchivements(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addAchivements = (data) => {
return APIService.api().post(`/achivements`,data)
}
export const updateAchivements = (data) => {
return APIService.api().put(`/achivements/${data.id}`,data)
}
export const getAllAchivements = (page,paginator) => {
return APIService.api().get(`/achivements/?page=${page}&paginator=${paginator}`)
}
export const getOneAchivements = (id) => {
return APIService.api().get(`/achivements/${id}`)
}
export const searchAchivements = (searchKey,page,paginator) => {
return APIService.api().get(`/achivements/search/${searchKey}/?page=${page}&paginator=${paginator}`)
}
export const deleteAchivements = (id) => {
return APIService.api().delete(`/achivements/${id}`)
}
