export const MenuItems = [
    { title: 'Dashboard', path: '/dashboard', icon: 'fas fa-fw fa-tachometer-alt', subMenu: [] },
    { title: 'Videos', path: '/videos', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Suggestion', path: '/suggestion', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Photos', path: '/photos', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'News', path: '/news', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Events', path: '/events', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Enquiry', path: '/enquiry', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Careers', path: '/careers', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Achivements', path: '/achivements', icon: 'fas fa-fw fa-table',subMenu: []},

]
