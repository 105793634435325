import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAchivements {
id:number,
achivement:string,
created_at?:Date,
updated_at?:Date
}

interface IAchivementsData {
    list?: Array<IAchivements>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IAchivementsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const achivementsSlice = createSlice({
    name: "achivements",
    initialState,
    reducers: {
        setAchivementsList: (state, _action: PayloadAction<IAchivementsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetAchivementsToInit: (state) => {
            state = initialState;
        },
        setAchivementsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setAchivementsList, resetAchivementsToInit, setAchivementsMessage } = achivementsSlice.actions;

export default achivementsSlice.reducer;

