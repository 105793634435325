import { APIService } from "services";

export const getVideos = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllVideos(pageNo,pageSize);
    }
    else{
        try {
            res = await searchVideos(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addVideos = (data) => {
return APIService.api().post(`/videos`,data)
}
export const updateVideos = (data) => {
return APIService.api().put(`/videos/${data.id}`,data)
}
export const getAllVideos = (page,paginator) => {
return APIService.api().get(`/videos/?page=${page}&paginator=${paginator}`)
}
export const getOneVideos = (id) => {
return APIService.api().get(`/videos/${id}`)
}
export const searchVideos = (searchKey,page,paginator) => {
return APIService.api().get(`/videos/search/${searchKey}/?page=${page}&paginator=${paginator}`)
}
export const deleteVideos = (id) => {
return APIService.api().delete(`/videos/${id}`)
}
